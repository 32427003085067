/**
 * Returns translation for the statistics object
 * @param input
 */
export const parseStatisticsKey = (input: string) => {
    const parseObject: Record<string, string> = {
        cancel: 'Abbruch',
        jobCenter: 'Durch Jobcenter',
        sickness: 'Wg. Krankheit',
        measureConflictingManner: 'Wg. maßnahmenwidrigen Verhalten',
        pregnancy: 'Wg. Schwangerschaft',
        penalSystem: 'Wg. Strafvollzug',
        mentalOverloadOrUnderload: 'Wg. Über-/Unterforderung',
        relocation: 'Wg. Umzug',
        noClaimForAg2: 'Kein Anspruch auf ALGII-Leistung',
        successful: 'Erfolgreicher Abschluss',
        workOrMinijob: 'Arbeit/Minijob/400,- Euro',
        job: 'Arbeitsstelle',
        companyExternalApprenticeship: 'Außerbetriebliche Ausbildung',
        entranceExamSuccess: 'Erwerb der Berufsreife',
        companyApprenticeship: 'Betriebliche Ausbildung',
        vocationalPreparation: 'Betriebliche Berufsvorbereitung',
        schoolApprenticeship: 'schulische Ausbildung',
        selfEmployedJob: 'Selbstständigkeit',
        changeToSecondarySchool: 'Wechsel zur weiterführenden Schule',
        otherReasons: 'Andere Gründe',
        civilOrMilitaryDuty: 'Bundesfreiwilligendienst / freiwilliger Wehrdienst',
        youthFreeService: 'Einmündung in Jugendfreiwilligendienst',
        newParticipation: 'Erneute Teilnahme',
        ownAbandonment: 'Freiwilliger Verzicht',
        languageCourse: 'Sprachkurs',
        tooOld: 'Ü25 / noch nicht untergebracht',
        unknown: 'Verbleib unbekannt',
        changeToSgb3Measure: 'Wechsel in andere SGB III-Maßnahme',
        changeToSgb2Measure: 'Wechsel in andere SGB II-Maßnahme',
        changeToBvb: 'Wechsel in eine BVB',
        changeToVocationalPreparationSchool: 'Wechsel in eine schulische Berufsvorbereitung',
        changeToEq: 'Wechsel in EQ',
        averagePresentPercentage: 'Durchschnittliche Anwesenheit der Teilnehmer',
        amountOfInternships: 'Anzahl der geleisteten Praktika',
        averageDurationOfInternships: 'Durschschnittliche Dauer der Praktika',
        amountOfActionRequired: 'Anzahl der festgestellten Handlungsbedarfe',
        actionRequiredDone: 'davon abgebaut',
        actionRequiredPartlyDone: 'erreichte Teilziele'
    };

    return parseObject[input];
};
