/**
 * Map graduation properties to readable words
 * @param value
 */
import {
    ParticipantGraduation,
    ParticipantLivingSituation,
    ParticipantMobility,
    ParticipantPersonalStatus,
    ParticipantProfessionalTraining
} from './Types';

export const participantGraduation: ParticipantGraduation[] = [
    'left_school_without_graduation_diploma',
    'secondary_school_after_9_grade_diploma',
    'secondary_school_after_10_grade_diploma',
    'middle_school_or_technical_secondary_school_diploma',
    'technical_diploma',
    'high_school_diploma',
    'special_school_diploma',
    'other_diploma'
];
export const participantProfessionalTraining: ParticipantProfessionalTraining[] = [
    'no_high_graduation',
    'professional_education',
    'vocational_school',
    'academy_bachelor',
    'academy_master',
    'other_graduation'
];
export const participantPersonalStatus: ParticipantPersonalStatus[] = [
    'personal_status_single',
    'personal_status_quasi_marital',
    'personal_status_married',
    'personal_status_dissociated',
    'personal_status_singled',
    'personal_status_widowed',
    'in_other_personal_status'
];
export const participantLivingSituation: ParticipantLivingSituation[] = [
    'living_alone',
    'living_with_partner',
    'living_with_children',
    'living_with_family',
    'living_with_flat_share',
    'living_at_parents',
    'living_homeless',
    'living_in_other_situation'
];
export const participantMobility: ParticipantMobility[] = [
    'mobile_with_car',
    'mobile_with_motorcycle',
    'mobile_with_moped',
    'mobile_with_other'
];

/**
 * Map backend enums to readable format
 * @param graduation
 */
export const mapGraduation = (graduation: ParticipantGraduation | undefined) => {
    switch (graduation) {
        case 'high_school_diploma':
            return 'Abitur (Hochschulreife)';
        case 'left_school_without_graduation_diploma':
            return 'Schule ohne Abschluss verlassen';
        case 'middle_school_or_technical_secondary_school_diploma':
            return 'Mittlerer Schulabschluss (Fachoberschulreife, Realschulabschluss)';
        case 'other_diploma':
            return 'Sonstiger Abschluss';
        case 'secondary_school_after_9_grade_diploma':
            return 'Hauptschulabschluss nach Kl. 9';
        case 'secondary_school_after_10_grade_diploma':
            return 'Hauptschulabschluss nach Kl. 10';
        case 'special_school_diploma':
            return 'Förderschulabschluss';
        case 'technical_diploma':
            return 'Fachhochschulreife (Fachabitur)';
    }
};

/**
 * Map backend enums to readable format
 * @param training
 */
export const mapProfessionalTraining = (training: ParticipantProfessionalTraining | undefined) => {
    switch (training) {
        case 'academy_bachelor':
            return 'Fachschule/Fachhochschule/Bachelor';
        case 'academy_master':
            return 'Universität (Master)';
        case 'no_high_graduation':
            return 'Ohne abgeschlossene Berufsausbildung';
        case 'other_graduation':
            return 'Sonstiger Abschluss';
        case 'professional_education':
            return 'betriebliche/außerbetriebliche Berufsausbildung';
        case 'vocational_school':
            return 'Berufsfachschule';
    }
};

/**
 * Function to receive the readable personal status
 * @param status
 */
export const mapParticipantPersonalStatus = (status: ParticipantPersonalStatus | undefined) => {
    switch (status) {
        case 'personal_status_single':
            return 'ledig';
        case 'personal_status_quasi_marital':
            return 'eheähnliche Gemeinschaft';
        case 'personal_status_married':
            return 'verheiratet';
        case 'personal_status_dissociated':
            return 'getrennt lebend';
        case 'personal_status_singled':
            return 'geschieden';
        case 'personal_status_widowed':
            return 'verwitwet';
        case 'in_other_personal_status':
            return 'sonstiges';
        default:
            return '-';
    }
};

/**
 * Map the participant living situation attribute into a readable format
 * @param livingSituation
 */
export const mapParticipantLivingSituation = (livingSituation: ParticipantLivingSituation | undefined) => {
    switch (livingSituation) {
        case 'living_alone':
            return 'Alleinlebend';
        case 'living_with_partner':
            return 'mit Partner';
        case 'living_with_children':
            return 'mit Kind/er';
        case 'living_with_family':
            return 'mit Familie (Partner+Kind/er)';
        case 'living_with_flat_share':
            return 'in Wohngemeinschaft';
        case 'living_at_parents':
            return 'bei Eltern';
        case 'living_homeless':
            return 'wohnungslos';
        case 'living_in_other_situation':
            return 'sonstiges';
        default:
            return '-';
    }
};

/**
 * Map mobility prop to a readable format
 * @param mobility
 */
export const mapParticipantMobility = (mobility: ParticipantMobility | undefined) => {
    switch (mobility) {
        case 'mobile_with_car':
            return 'PKW';
        case 'mobile_with_motorcycle':
            return 'Motorrad';
        case 'mobile_with_moped':
            return 'Motorroller/Moped';
        case 'mobile_with_other':
            return 'andere';
    }
};
